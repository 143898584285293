<template>
  <div class="page" :style="{'minHeight':cHeight}">
    <div class="top">
      <div class="choose" @click="choose('可使用')">
        <div>可使用</div>
        <div class="line" :style="{'background':selected=='可使用'?'#21D0C8':'#fff'}"></div>
      </div>
      <div class="choose" @click="choose('已失效')">
        <div>已失效</div>
        <div class="line" :style="{'background':selected=='已失效'?'#21D0C8':'#fff'}"></div>
      </div>
      <div class="roule" @click="$router.push({path:'/rule'})">使用规则</div>
    </div>
    <div class="list" v-for="(item,index) in list" :key="index" :style="{'opacity':selected=='已失效'?0.5:''}">
      <div class="condition" v-if="item.MemberTitle">{{item.MemberTitle}}</div>
      <div class="con">
        <div class="money">
          <span>¥</span>
          {{item.Price&&item.Price!=0?item.Price:'免费'}}
        </div>
        <div>
          <div class="title">{{item.CouponsName}}</div>
          <div class="times">{{item.EndDate.substring(0,10)}}到期</div>
        </div>
        <div class="butn" @click="goUse(item.CouponsType)"
          :style="{'background':selected=='已失效'?'#D3D3D3':'linear-gradient(-36deg, #E93939 0%, #F95F53 100%)'}">去使用
        </div>
      </div>
      <div class="des">
        <img src="../assets/ticket_icon_hint.png" alt="">
        <span>{{item.CouponType}}</span>
      </div>
    </div>
    <div class="mark" v-if="showCode">
      <div class="m_box">
        <div class="m_tip">温馨提示</div>
        <div class="m_please">请下载客户端APP查看并使用优惠券</div>
        <div class="m_btns">
          <div class="m_stop" @click="showCode=false">暂不下载</div>
          <div class="m_down" @click="downLoad">立即下载</div>
        </div>
      </div>
    </div>
    <div class="nodata" v-if="list.length==0">
      <img src="../assets/universal_default.png" alt="">
      <div>暂无优惠券</div>
    </div>
  </div>
</template>

<script>
  import {
    YHList
  } from '@/api'
  export default {
    data() {
      return {
        cHeight: null,
        selected: '可使用',
        list: [],
        showCode: false
      }
    },
    mounted() {
      this.cHeight = (document.documentElement.clientHeight || document.body.clientHeight) + 'px'
      this.getList()
    },
    methods: {
      downLoad() {
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        this.showCode = false
        if (isAndroid) {
          window.location = "http://dl.ilvdo.com/download/akh";
        } else {
          window.location = "https://itunes.apple.com/cn/app/id992714103?mt=8";
        }
      },
      choose(e) {
        this.selected = e
        this.getList()
      },
      getList() {
        this.list = []
        this.$toast.loading();
        let user = JSON.parse(localStorage.getItem('user'));
        let data = {
          "memberGuid": user.MemberGuid,
          "filter": this.selected,
          "type": ""
        }
        YHList(data).then(res => {
          if (res.state == 0) {
            this.list = res.data
          }
          this.$toast.clear()
        })
      },
      goUse(type) {
        _hmt.push(['_trackEvent', 'click', '优惠券-去使用', 'mob']);
        let id = 1;
        if (type == '电话') {
          id = 2
        }
        if (type == '会员') {
          this.$router.push({
            path: '/member',
          })
          return
        }
        if (type == '代金券' || type == '文书代写' || type == '律师函' || type == '私人律师') {
          this.showCode = true
          return
        }
        this.$router.push({
          path: '/ask',
          query: {
            id
          }
        })
      }
    }
  }

</script>
<style scoped lang='scss'>
  .page {
    padding-top: 100px;
    background: #F5F5F8;
    box-sizing: border-box;
  }

  .top {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 60px 10px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    z-index: 9;
  }

  .roule {
    position: fixed;
    top: 20px;
    right: 0;
    width: 129px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgba($color: #AAAAAA, $alpha: 0.2);
    border-radius: 20px 0px 0px 20px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    z-index: 10;

  }

  .choose {
    padding-top: 20px;
    margin: 0 20px;
    width: 100px;
    color: #333333;
    font-size: 28px;
    text-align: center;
    box-sizing: border-box;
  }

  .line {
    margin: 6px auto 0;
    width: 30px;
    height: 8px;
    border-radius: 4px;
  }

  .list {
    position: relative;
    margin: 30px auto;
    padding-bottom: 15px;
    width: 690px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    overflow: hidden;
  }

  .condition {
    position: absolute;
    left: 0;
    top: 0;
    width: 180px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    background: #F24537;
    border-radius: 20px 0px 20px 0px;
  }

  .con {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 29px 14px 46px;
    padding: 44px 0 30px;
    border-bottom: 1px solid #F5F5F8;
  }

  .money {
    font-size: 40px;
    color: #E93939;
    font-weight: bold;
    width: 135px;

    span {
      font-size: 24px;
    }
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .times {
    margin-top: 15px;
    font-size: 26px;
    font-weight: 500;
    color: #666666;
  }

  .butn {
    width: 110px;
    height: 44px;
    text-align: center;
    line-height: 46px;
    background: linear-gradient(-36deg, #E93939 0%, #F95F53 100%);
    border-radius: 22px;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
  }

  .des {
    display: flex;
    align-items: center;
    margin-left: 46px;

    img {
      margin-right: 20px;
      width: 30px;
      height: 30px;
    }

    span {
      font-size: 24px;
      font-weight: bold;
      color: #FF8400;
    }
  }

  .nodata {
    margin: 25vh auto;
    text-align: center;

    img {
      width: 228px;
      height: 220px;
    }

    div {
      text-align: center;
      font-size: 24px;
      color: #666666;
    }
  }

  .mark {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.7);

    .m_box {
      width: 90%;
      height: 360px;
      background: #fff;
      border-radius: 20px;

      .m_tip {
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        color: #333;
        padding: 40px;
      }

      .m_please {
        font-size: 30px;
        color: #333;
        text-align: center;
      }

      .m_btns {
        display: flex;
        justify-content: center;
        margin-top: 50px;

        div {
          margin: 0 20px;
          width: 240px;
          border: 2px solid #00C1B1;
          border-radius: 60px;
          text-align: center;
          height: 80px;
          line-height: 80px;
          font-size: 30px;
        }

        .m_stop {
          color: #00C1B1;
        }

        .m_down {
          color: #fff;
          background: #00C1B1;
        }
      }
    }
  }

</style>
